import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"display\":\"swap\",\"weight\":[\"400\",\"600\"],\"variable\":\"--font-inter-tight\",\"subsets\":[\"latin\"]}],\"variableName\":\"interTight\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/NavigationV2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/ScrollTop.tsx");
