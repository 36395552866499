'use client';
import Link from 'next/link';
import Image from 'next/image';
import Lottie from "lottie-react";
import { useTranslations } from 'next-intl';

import NavLink from '@/components/NavLink';
import LocaleSwitcher from '@/components/LocaleSwitcher';
import CTAPrimary from './ui/ctaPrimary';
import attractMoney from '@/components/animations/attract-money.json';

const Footer: React.FC = () => {
  const tNavigation = useTranslations('Navigation');
  const tFooter = useTranslations('Footer');

  return (
    <>
      <footer>
        <div className='w-full footer-bg relative overflow-hidden z-10'>
          <div className="flex flex-col w-full h-[80%] absolute top-0 bg-white"></div>
          <div className='w-full bg-brand-blue-mid text-white py-10 md:py-12 colmena-p-alt colmena-rounded relative z-10'>
            <div className='max-w-screen-2xl lg:colmena-p mx-auto grid grid-cols-4 md:grid-cols-5 gap-y-10 md:gap-y-12 gap-x-4 relative z-2'>
              <div className='col-span-4 md:col-span-3'>
                <h3 className='text-3xl lg:text-4xl max-w-[90%] md:max-w-[50%] mb-8'>{tFooter('brandStatement')}</h3>
                <p className="max-w-96">{tFooter('brandIntro')}</p>
                <CTAPrimary text={tFooter('visitPortfolio')} extraClass="mt-8 inline-block" href='/portfolio' />
              </div>
              <div className='col-span-2 md:col-span-2 lg:col-span-1 lg:pt-2'>
                <ul className='grid gap-y-5 md:gap-y-6 text-sm md:text-sm mb-0 md:mb-0 md:text-right lg:text-left footer-links'>
                  <li><NavLink href="/">{tNavigation('home')}</NavLink></li>
                  <li><NavLink href="/about">{tNavigation('about')}</NavLink></li>
                  <li><NavLink href="/websites">{tNavigation('websites')}</NavLink></li>
                  <li><NavLink href="/portfolio">{tNavigation('portfolio')}</NavLink></li>
                  <li><NavLink href="/contact">{tNavigation('contact')}</NavLink></li>
                </ul>
              </div>
              <div className='col-span-2 md:col-span-5 lg:col-span-1 text-sm text-right md:text-left lg:text-right flex flex-col gap-y-1 md:flex-row md:justify-between md:items-center md:pt-0 lg:flex-col md:items-stretch lg:pt-2'>
                <div>
                  <p>9100 S Dadeland Blvd.</p>
                  <p>Miami - FL 33156</p>
                </div>
                <ul className='footer-social-media'>
                  <li>
                    <Link href='https://facebook.com/colmenadigital' target='_blank'>
                      <Image src="/icons/002-facebook.svg" alt="Facebook @ColmenaDigital" fill priority={false} />
                    </Link>
                  </li>
                  <li>
                    <Link href='https://www.instagram.com/colmenadigital' target='_blank'>
                      <Image src="/icons/003-instagram.svg" alt="Instagram @ColmenaDigital" fill priority={false} />
                    </Link>
                  </li>
                  <li>
                    <Link href='https://www.behance.net/colmenadigital' target='_blank'>
                      <Image src="/icons/001-behance.svg" alt="Behance @ColmenaDigital" fill priority={false} />
                    </Link>
                  </li>
                  <li>
                    <Link href='https://www.linkedin.com/company/colmena-digital' target='_blank'>
                      <Image src="/icons/004-linkedin.svg" alt="Linkedin @Colmena-Digital" fill priority={false} />
                    </Link>
                  </li>
                </ul>
                <div className='py-5 flex justify-end md:justify-start lg:justify-end'>
                  <LocaleSwitcher showLabel={true} />
                </div>
              </div>
              <div className='col-span-4 md:col-span-5 text-left text-xs text-neutral-300 flex justify-between items-center gap-x-4'>
                <NavLink
                  href="/"
                  className='relative w-48 md:w-56 lg:w-72 min-h-10 block transition-all'
                >
                  <Image
                    src='/images/colmena-digital-logo-dark.svg'
                    alt='Logo Colmena Digital LLC'
                    className='object-contain object-left'
                    priority={false}
                    fill
                  />
                </NavLink>
                <p>{tFooter('madeIn')} ❤️</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className='w-full footer-bg sticky bottom-0 overflow-hidden z-0'
        >
          <div
            className="max-w-screen-2xl mx-auto px-6 md:px-12 pt-12 pb-16 md:py-4"
          >
            <div className="flex justify-around items-center gap-x-2 md:gap-x-4">
              <div className='h-28 w-28 p-0 hidden md:block overflow-hidden'>
                <Lottie animationData={attractMoney} loop={true} className='object-contain scale-110' />
              </div>
              <p className='w-48 md:w-fit'>{tFooter('ctaTitle')}</p>
              <CTAPrimary text={tFooter('ctaText')} mode='dark' hover='hover:bg-brand-yellow hover:text-black' href='/contact' />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
